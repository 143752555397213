import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import ServicePage from "../../components/templates/ServicePage"

const query = graphql`
  {
    allContentfulInstallationImages(
      filter: {
        metadata: {
          tags: {
            elemMatch: { contentful_id: { eq: "fountainscapesSpillways" } }
          }
        }
      }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          id
          title
        }
      }
    }
  }
`

export default function FountainscapesPage() {
  const title = `Fountainscapes & Spillway` // Singular (not plural)
  const {
    allContentfulInstallationImages: { nodes: image },
  } = useStaticQuery(query)

  const imageList = image[0].image


  return (
    <Layout>
      <Navbar />
      <Seo
        title={`${title}s`}
        description={`These smaller ${title}s can create a focal point in your yard. Popular designs include bubbling rocks, pots or urns, and spillway bowls.`}
      />

      <ServicePage
        imageList={imageList}
        title={title}
        img={
          <StaticImage
            // src="../../assets/images/Fountainscapes and Spillways_Andreatta Waterscapes_Central Point, Oregon.jpg"
            src="../../assets/images/Fountains_Andreatta Waterscapes_Medford Oregon.jpg"
            quality={90}
            loading="eager"
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 1,
              height: "21rem",
            }}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Backyard Stream built by Andreatta Waterscapes"
          />
        }
        tagline={`The perfect complement to your landscape`}
        body={[
          {
            id: 0,
            type: "paragraph",
            content: `An Andreatta Waterscapes outdoor fountain can bring a fun splash of water to your yard or garden. There are a variety of sizes and styles to choose from. Popular designs include bubbling rocks, pots or urns, basalt pillars and spillway bowls.`,
          },
          {
            id: 1,
            type: "paragraph",
            content: `These smaller water features can be installed as a stand-alone option or as an add-on to an existing pond. A well-placed outdoor fountain can create a focal point in your garden or be the perfect touch near your patio to bring the tranquility of water to your backyard retreat.`,
          },
          {
            id: 2,
            type: "paragraph",
            content: `While many people choose to have an outdoor fountain as a compliment to their backyard landscaping, these are also a great choice for front yard water features, and for using around entrances to your home. `,
          },
        ]}
      />
    </Layout>
  )
}
